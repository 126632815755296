
 .medical-service-title{
  display: inline-block;
  width: 100%;
  text-align: left;
  background: linear-gradient(132.52deg, #004dc5 17.65%, #002d73 93.91%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  @apply tw-uppercase sm:!tw-text-[5vw] max-sm:!tw-text-4xl;
 }

  .medical-service-grid-background{
  background: linear-gradient(180deg,
      rgba(63, 68, 74, 0.05) 0%,
      rgba(63, 68, 74, 0) 100%);
  }